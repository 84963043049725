export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const STATIC_PAGE = 'STATIC_PAGE';
export const REQUIRE_AUTHENTICATION = 'REQUIRE_AUTHENTICATION';
export const INITIAL_WHO_AM_I = 'INITIAL_WHO_AM_I';
export const WHO_AM_I = 'WHO_AM_I';
export const HOME = 'HOME';
export const HOME_AUX = 'HOME_AUX';
export const MEMORIES = 'MEMORIES';
export const CALENDAR_YEAR_DAYS = 'CALENDAR_YEAR_DAYS';
export const CALENDAR_MONTH_DAYS = 'CALENDAR_MONTH_DAYS';
export const CALENDAR_DATE_POSTS = 'CALENDAR_DATE_POSTS';
export const DISCUSSIONS = 'DISCUSSIONS';
export const SAVES = 'SAVES';
export const DIRECT = 'DIRECT';
export const GET_USER_FEED = 'GET_USER_FEED';
export const GET_USER_STATS = 'GET_USER_STATS';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SHOW_MORE_COMMENTS = 'SHOW_MORE_COMMENTS';
export const COMPLETE_POST_COMMENTS = 'COMPLETE_POST_COMMENTS';
export const SHOW_MORE_LIKES = 'SHOW_MORE_LIKES';
export const SHOW_MORE_LIKES_ASYNC = 'SHOW_MORE_LIKES_ASYNC';
export const SHOW_MORE_LIKES_SYNC = 'SHOW_MORE_LIKES_SYNC';
export const TOGGLE_EDITING_POST = 'TOGGLE_EDITING_POST';
export const CANCEL_EDITING_POST = 'CANCEL_EDITING_POST';
export const SAVE_EDITING_POST = 'SAVE_EDITING_POST';
export const DELETE_POST = 'DELETE_POST';
export const TOGGLE_COMMENTING = 'TOGGLE_COMMENTING';
export const ADD_COMMENT = 'ADD_COMMENT';
export const LIKE_POST_OPTIMISTIC = 'LIKE_POST_OPTIMISTIC';
export const LIKE_POST = 'LIKE_POST';
export const UNLIKE_POST_OPTIMISTIC = 'UNLIKE_POST_OPTIMISTIC';
export const UNLIKE_POST = 'UNLIKE_POST';
export const CLEAN_LIKE_ERROR = 'CLEAN_LIKE_ERROR';
export const HIDE_POST = 'HIDE_POST';
export const UNHIDE_POST = 'UNHIDE_POST';
export const SAVE_POST = 'SAVE_POST';
export const TOGGLE_MODERATING_COMMENTS = 'TOGGLE_MODERATING_COMMENTS';
export const DISABLE_COMMENTS = 'DISABLE_COMMENTS';
export const ENABLE_COMMENTS = 'ENABLE_COMMENTS';
export const TOGGLE_EDITING_COMMENT = 'TOGGLE_EDITING_COMMENT';
export const SAVE_EDITING_COMMENT = 'SAVE_EDITING_COMMENT';
export const LIKE_COMMENT = 'LIKE_COMMENT';
export const UNLIKE_COMMENT = 'UNLIKE_COMMENT';
export const GET_COMMENT_LIKES = 'GET_COMMENT_LIKES';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const CREATE_POST = 'CREATE_POST';
export const ADD_ATTACHMENT_RESPONSE = 'ADD_ATTACHMENT_RESPONSE';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_OUT = 'SIGN_OUT';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';
export const UPDATE_USER_NOTIFICATION_PREFERENCES = 'UPDATE_USER_NOTIFICATION_PREFERENCES';
export const UPDATE_ACTUAL_USER_PREFERENCES = 'UPDATE_ACTUAL_USER_PREFERENCES';
export const TOGGLE_REALTIME = 'TOGGLE_REALTIME';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_USER_PICTURE = 'UPDATE_USER_PICTURE';
export const GET_SINGLE_POST = 'GET_SINGLE_POST';
export const BAN = 'BAN';
export const UNBAN = 'UNBAN';
export const SUBSCRIBE = 'SUBSCRIBE';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const UNSUBSCRIBE_FROM_ME = 'UNSUBSCRIBE_FROM_ME';
export const SEND_SUBSCRIPTION_REQUEST = 'SEND_SUBSCRIPTION_REQUEST';
export const GET_USER_COMMENTS = 'GET_USER_COMMENTS';
export const GET_USER_LIKES = 'GET_USER_LIKES';
export const GET_USER_MEMORIES = 'GET_USER_MEMORIES';
export const TOGGLE_HIDDEN_POSTS = 'TOGGLE_HIDDEN_POSTS';
export const SUBSCRIBERS = 'SUBSCRIBERS';
export const SUBSCRIPTIONS = 'SUBSCRIPTIONS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const CREATE_GROUP = 'CREATE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_PICTURE = 'UPDATE_GROUP_PICTURE';
export const ACCEPT_GROUP_REQUEST = 'ACCEPT_GROUP_REQUEST';
export const REJECT_GROUP_REQUEST = 'REJECT_GROUP_REQUEST';
export const TOGGLE_PINNED_GROUP = 'TOGGLE_PINNED_GROUP';
export const ACCEPT_USER_REQUEST = 'ACCEPT_USER_REQUEST';
export const REJECT_USER_REQUEST = 'REJECT_USER_REQUEST';
export const RESET_GROUP_CREATE_FORM = 'RESET_GROUP_CREATE_FORM';
export const RESET_GROUP_UPDATE_FORM = 'RESET_GROUP_UPDATE_FORM';
export const REALTIME_POST_NEW = 'REALTIME_POST_NEW';
export const REALTIME_POST_UPDATE = 'REALTIME_POST_UPDATE';
export const REALTIME_POST_DESTROY = 'REALTIME_POST_DESTROY';
export const REALTIME_POST_HIDE = 'REALTIME_POST_HIDE';
export const REALTIME_POST_UNHIDE = 'REALTIME_POST_UNHIDE';
export const REALTIME_POST_SAVE = 'REALTIME_POST_SAVE';
export const REALTIME_COMMENT_NEW = 'REALTIME_COMMENT_NEW';
export const REALTIME_COMMENT_UPDATE = 'REALTIME_COMMENT_UPDATE';
export const REALTIME_COMMENT_DESTROY = 'REALTIME_COMMENT_DESTROY';
export const REALTIME_LIKE_NEW = 'REALTIME_LIKE_NEW';
export const REALTIME_LIKE_REMOVE = 'REALTIME_LIKE_REMOVE';
export const REALTIME_GLOBAL_USER_UPDATE = 'REALTIME_GLOBAL_USER_UPDATE';
export const UNSUBSCRIBE_FROM_GROUP = 'UNSUBSCRIBE_FROM_GROUP';
export const MAKE_GROUP_ADMIN = 'MAKE_GROUP_ADMIN';
export const UNADMIN_GROUP_ADMIN = 'UNADMIN_GROUP_ADMIN';
export const REVOKE_USER_REQUEST = 'REVOKE_USER_REQUEST';
export const BLOCKED_BY_ME = 'BLOCKED_BY_ME';
export const GET_SUMMARY = 'GET_SUMMARY';
export const GET_USER_SUMMARY = 'GET_USER_SUMMARY';
export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_VALIDATION_FAIL = 'RESET_PASSWORD_VALIDATION_FAIL';
export const GET_SEARCH = 'GET_SEARCH';
export const GET_BEST_OF = 'GET_BEST_OF';
export const GET_EVERYTHING = 'GET_EVERYTHING';
export const RESET_SETTINGS_FORMS = 'RESET_SETTINGS_FORMS';
export const REALTIME_CONNECTED = 'REALTIME_CONNECTED';
export const REALTIME_SUBSCRIBE = 'REALTIME_SUBSCRIBE';
export const REALTIME_UNSUBSCRIBE = 'REALTIME_UNSUBSCRIBE';
export const REALTIME_INCOMING_EVENT = 'REALTIME_INCOMING_EVENT';
export const DIRECTS_ALL_READ = 'DIRECTS_ALL_READ';
export const NOTIFICATIONS_ALL_READ = 'NOTIFICATIONS_ALL_READ';
export const REALTIME_USER_UPDATE = 'REALTIME_USER_UPDATE';
export const ARCHIVE_ACTIVITY_REQUEST = 'ARCHIVE_ACTIVITY_REQUEST';
export const ARCHIVE_RESTORATION_REQUEST = 'ARCHIVE_RESTORATION_REQUEST';
export const RESET_ARCHIVE_FORMS = 'RESET_ARCHIVE_FORMS';
export const GET_POST_ID_BY_OLD_NAME = 'GET_POST_ID_BY_OLD_NAME';
export const SEND_INVITE = 'SEND_INVITE';
export const CREATE_FREEFEED_INVITATION = 'CREATE_FREEFEED_INVITATION';
export const GET_INVITATION = 'GET_INVITATION';
export const SERVER_TIME_AHEAD = 'SERVER_TIME_AHEAD';
export const TOGGLE_FEED_SORT = 'TOGGLE_FEED_SORT';
export const SET_SYSTEM_COLOR_SCHEME = 'SET_SYSTEM_COLOR_SCHEME';
export const SET_USER_COLOR_SCHEME = 'SET_USER_COLOR_SCHEME';
export const SET_NSFW_VISIBILITY = 'SET_NSFW_VISIBILITY';
export const SET_UI_SCALE = 'SET_UI_SCALE';
export const GET_APP_TOKENS = 'GET_APP_TOKENS';
export const GET_APP_TOKENS_SCOPES = 'GET_APP_TOKENS_SCOPES';
export const CREATE_APP_TOKEN = 'CREATE_APP_TOKEN';
export const REISSUE_APP_TOKEN = 'REISSUE_APP_TOKEN';
export const DELETE_APP_TOKEN = 'DELETE_APP_TOKEN';
export const DELETE_APP_TOKEN_ID = 'DELETE_APP_TOKEN_ID';
export const UPDATE_APP_TOKEN = 'UPDATE_APP_TOKEN';
export const USER_CARD_CLOSING = 'USER_CARD_CLOSING';
export const GET_SERVER_INFO = 'GET_SERVER_INFO';
export const GET_AUTH_PROFILES = 'GET_AUTH_PROFILES';
export const CONNECT_TO_EXTERNAL_PROVIDER = 'CONNECT_TO_EXTERNAL_PROVIDER';
export const UNLINK_EXTERNAL_PROFILE = 'UNLINK_EXTERNAL_PROFILE';
export const SIGN_IN_VIA_EXTERNAL_PROVIDER = 'SIGN_IN_VIA_EXTERNAL_PROVIDER';
export const HIDE_BY_CRITERION = 'HIDE_BY_CRITERION';
export const UNHIDE_CRITERIA = 'UNHIDE_CRITERIA';
export const REMOVE_RECENTLY_HIDDEN_POST = 'REMOVE_RECENTLY_HIDDEN_POST';
export const GET_ALL_GROUPS = 'GET_ALL_GROUPS';
export const LIST_HOME_FEEDS = 'LIST_HOME_FEEDS';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const CREATE_HOME_FEED = 'CREATE_HOME_FEED';
export const UPDATE_HOME_FEED = 'UPDATE_HOME_FEED';
export const DELETE_HOME_FEED = 'DELETE_HOME_FEED';
export const REORDER_HOME_FEEDS = 'REORDER_HOME_FEEDS';
export const GET_ALL_SUBSCRIPTIONS = 'GET_ALL_SUBSCRIPTIONS';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const CREATE_ATTACHMENT = 'CREATE_ATTACHMENT';
export const SET_ATTACHMENT = 'SET_ATTACHMENT';
export const REISSUE_AUTH_SESSION = 'REISSUE_AUTH_SESSION';
export const AUTH_TOKEN_UPDATED = 'AUTH_TOKEN_UPDATED';
export const LIST_AUTH_SESSIONS = 'LIST_AUTH_SESSIONS';
export const CLOSE_AUTH_SESSIONS = 'CLOSE_AUTH_SESSIONS';
export const SET_BETA_CHANNEL = 'SET_BETA_CHANNEL';
export const APP_VERSION = 'APP_VERSION';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const SET_SUBMIT_MODE = 'SET_SUBMIT_MODE';
export const LEAVE_DIRECT = 'LEAVE_DIRECT';
export const GET_ATTACHMENTS_STATS = 'GET_ATTACHMENTS_STATS';
export const SANITIZE_MEDIA = 'SANITIZE_MEDIA';
export const GET_COMMENT_BY_NUMBER = 'GET_COMMENT_BY_NUMBER';
export const GET_GROUP_BLOCKED_USERS = 'GET_GROUP_BLOCKED_USERS';
export const BLOCK_USER_IN_GROUP = 'BLOCK_USER_IN_GROUP';
export const UNBLOCK_USER_IN_GROUP = 'UNBLOCK_USER_IN_GROUP';
export const SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE';
export const GET_INVITATIONS_INFO = 'GET_INVITATIONS_INFO';
export const DISABLE_BANS_IN_GROUP = 'DISABLE_BANS_IN_GROUP';
export const ENABLE_BANS_IN_GROUP = 'ENABLE_BANS_IN_GROUP';
export const GET_POSTS_BY_IDS = 'GET_POSTS_BY_IDS';
export const GET_COMMENTS_BY_IDS = 'GET_COMMENTS_BY_IDS';
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const TRANSLATE_TEXT = 'TRANSLATE_TEXT';
export const GET_BACKLINKS = 'GET_BACKLINKS';
export const NOTIFY_OF_ALL_COMMENTS = 'NOTIFY_OF_ALL_COMMENTS';
export const SET_ORBIT = 'SET_ORBIT';
